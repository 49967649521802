<template>
  <button
    class="menu-button"
    :class="{ open }"
    @click="$emit('update:open', !open)"
  >
    <div class="inner">
      <div class="plus">
        <span />
        <span />
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: ["open"],
};
</script>

<style>
.menu-button {
  margin: 2.3rem 0;
  padding: 1.2rem;
  border: none;
  background: none;
}

.menu-button .inner {
  position: relative;
  width: 25px;
  height: 25px;
  /*outline: 1px solid #eee;*/
}

.menu-button .plus {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 0.15s ease-in-out;
}

.menu-button .plus span {
  position: absolute;
  display: block;
  background: var(--primary-color);
}

.menu-button .plus span:nth-child(1) {
  width: 25px;
  height: 1px;
  top: 12px;
}

.menu-button .plus span:nth-child(2) {
  width: 1px;
  height: 25px;
  left: 12px;
}

.menu-button.open .plus {
  transform: rotate(45deg);
}
</style>
